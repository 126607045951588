@import "node_modules/bootstrap/scss/bootstrap.scss";

body {
    font-size: x-large;
    // text-align: center;
}

p {
    text-align: justify;
}

.text-center p {
    // text-align: center;
}

nav ul {
    font-size: large;
    font-weight: bold;
    text-align: center;
}
